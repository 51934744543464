const phoneNumber = '+1-828-229-7326';
const email = 'support@zustravel.com';
const companyName = 'Zustravel';
const companyAddress = `Zustravel LLC, 30 N. Gould St Ste 4000, Sheridan, WY 82801`;

const adminpanelurl = 'adminzustravel'; // login url of backend
const adminpanelhome= 'admin'; //// home backend url

///// backend url

// const urlbc = 'http://localhost:8000'; // local work

const urlbc = 'https://backend-one-beta-78.vercel.app'; 


// const mainColor = '#048c9b';

export { phoneNumber, email, companyName, companyAddress, adminpanelurl, adminpanelhome, urlbc };
